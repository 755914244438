import React, { useEffect, useState } from "react";
import {
  formatNumber,
  getAuthUser,
  getCurrentDate,
  getUserName,
  sellIPO,
} from "../../../firebase/firestore";
import "./style.css";

export default function SellIpoModal({ isOpen, onClose, ipo }) {
  const [numberOfSharesToSell, setNumberOfSharesToSell] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = getAuthUser();

  const handleSellShares = async () => {
    if (!numberOfSharesToSell || numberOfSharesToSell <= 0) {
      setError("Number of shares must be greater than 0");
      setTimeout(() => setError(""), 4000);
      return;
    }

    if (numberOfSharesToSell > ipo.numberOfShares) {
      setError(
        "Number of shares to sell must be less than or equal to the number of shares you have."
      );
      setTimeout(() => setError(""), 4000);
      return;
    }
    const userName = await getUserName();
    const sellingData = {
      numberOfShares: parseFloat(numberOfSharesToSell),
      logo: ipo.logo,
      name: ipo.name,
      date: getCurrentDate(),
      sharePrice: ipo.sharePrice,
      type: "sell",
      expectedDate: ipo.expectedDate,
      expListingPrice: ipo.expListingPrice,
      minInvestment: ipo.minInvestment,
      userName: userName,
    };
    setIsLoading(true);
    try {
      await sellIPO(userId, sellingData);
      setMessage("Shares sell request has been sent.");
      setTimeout(() => {
        setMessage("");
        setNumberOfSharesToSell(0);
        onClose();
      }, 2000);
    } catch (error) {
      setError(
        "There was an issue sending your sell request. Try again later."
      );
      console.error(error.message);
      setTimeout(() => setError(""), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  const handleNumberOfSharesChange = (e) => {
    const value = Number(e.target.value);
    setNumberOfSharesToSell(isNaN(value) ? 0 : value);
  };

  const totalCost = numberOfSharesToSell * ipo.sharePrice;

  return (
    <div className="invest_ipo_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="invest_ipo_modal">
        <div className="section_header">
          <div className="logo">
            <img src={ipo.logo} alt={`${ipo.name} Logo`} />
          </div>
          <h2 className="title">{ipo.name}</h2>
        </div>
        <div className="section_body">
          <div className="more_dets">
            <p className="bold_text">IPO Expected Date:</p>
            <p className="reg_text">{ipo.expectedDate}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">IPO Share Price: </p>
            <p className="reg_text">$ {formatNumber(ipo.sharePrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Expected Listing Price:</p>
            <p className="reg_text">$ {formatNumber(ipo.expListingPrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Minimum Investment Amount:</p>
            <p className="reg_text">$ {formatNumber(ipo.minInvestment)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Number of Shares Available:</p>
            <p className="reg_text">{ipo.numberOfShares}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Total Cost:</p>
            <p className="reg_text">$ {formatNumber(totalCost)}</p>
          </div>
          <div className="input_group">
            <label htmlFor="title">Number Of Shares To Sell:</label>
            <input
              type="number"
              name="numberOfShares"
              placeholder="0"
              id="numberOfShares"
              value={numberOfSharesToSell}
              onChange={handleNumberOfSharesChange}
              min="0"
            />
          </div>
          
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button
            onClick={handleSellShares}
            disabled={isLoading}
            className="submit_btn"
          >
            {isLoading ? "Processing..." : "Sell"}
          </button>
          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
