import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import { formatNumber, getIpos } from "../../firebase/firestore";
import InvestModal from "./Modals/InvestModal";
import "./style.css";

export default function IPOs() {
  const [ipos, setIpos] = useState([]);
  const [iposModalOpen, setIposModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIpo, setSelectedIpo] = useState(null);

  useEffect(() => {
    const fetchIpos = async () => {
      setIsLoading(true);
      try {
        const fetchedIpos = await getIpos();
        setIpos(fetchedIpos);
      } catch (error) {
        console.error("Error occurred while fetching IPOs: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIpos();
  }, []); // The empty dependency array means this useEffect runs once when the component mounts

  const sortedIpos = [...ipos].sort((a, b) => a.index - b.index);
  
  return (
    <div className="iposPage_Wrapper">
      <div className="headerSection">
        <h2 className="title">IPOs</h2>
        <div className="svgWrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      <div className="contentBody">
        {!ipos || ipos.length === 0 ? (
          <h5>No IPOs Available.</h5>
        ) : isLoading ? (
          <LoadingScreen />
        ) : (
          sortedIpos.map((ipo, index) => (
            <div key={index} className="ipoCard">
              <div className="ipoDetails">
                <div className="ipoColumn">
                  <div className="companyLogoWrapper">
                    <img src={ipo.logo} alt="Logo" />
                  </div>
                  <div className="columnDetails">
                    <div className="companyDetails">
                      <p className="companyName"> {ipo.name} </p>
                    </div>
                    <div className="additionalDetails">
                      <div className="detailsRow">
                        <span className="regularText"> {ipo.description} </span>
                      </div>
                      <div className="detailsRow">
                        <p className="boldText">Expected IPO Date:</p>
                        <span className="regularText">
                          {" "}
                          {ipo.expectedDate}{" "}
                        </span>
                      </div>
                      <div className="detailsRow">
                        <p className="boldText">Pre-IPO Share Price:</p>
                        <span className="regularText">
                          {" "}
                          $ {formatNumber(ipo.preSharePrice)}{" "}
                        </span>
                      </div>
                      <div className="detailsRow">
                        <p className="boldText">Minimum Investment:</p>
                        <span className="regularText">
                          {" "}
                          $ {formatNumber(ipo.minInvestment)}{" "}
                        </span>
                      </div>
                      <div className="detailsRow">
                        <p className="boldText">Expected Listing Price:</p>
                        <span className="regularText">
                          {" "}
                          $ {formatNumber(ipo.expListingPrice)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setIposModalOpen(true);
                      setSelectedIpo(ipo);
                    }}
                    className="purchaseButton"
                  >
                    Invest Now
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
        <InvestModal
          isOpen={iposModalOpen}
          onClose={() => {
            setIposModalOpen(false);
            setSelectedIpo(null);
          }}
          ipo={selectedIpo}
        />
      </div>
    </div>
  );
}
