import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchPasswordPolicySetting,
  getCurrentDate,
  registerNewUser,
} from "../../../firebase/firestore";
import Select from "react-select";
import { IoEye, IoEyeOff } from "react-icons/io5";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import Swal from "sweetalert2";
import PhoneVerification from "../PhoneValidation";
import { useAuth } from "../../../authContext";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import logo from "../../../assets/white_logo.png";
import "./style.css";

const CountrySelect = ({ value, onChange }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const handleCountryChange = (selectedOption) => {
    onChange({ target: { name: "country", value: selectedOption } });
  };

  return (
    <Select options={countries} value={value} onChange={handleCountryChange} />
  );
};

const validatePassword = (pass, isStrongPolicy) => {
  if (isStrongPolicy) {
    // Strong password policy validation
    const regex = /^(?=.*\d)(?=.*[\W_]).{8,}$/;
    return regex.test(pass);
  } else {
    // Updated simple password policy validation
    return pass.length >= 6;
  }
};

export default function Register() {
  const initialFormState = {
    fullName: "",
    email: "",
    address: "",
    country: "",
    mobilePhone: "",
    password: "",
    confirmPassword: "",
    jointAccount: false,
    secondaryAccountHolder: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStrongPasswordPolicy, setIsStrongPasswordPolicy] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState(
    new Array(6).fill("")
  );
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationModal, setVerificationModal] = useState(false);
  const [counter, setCounter] = useState(15);
  const [canResend, setCanResend] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [ showTooltip, setShowTooltip ] = useState(false);
  const auth = getAuth();

  const { setPhoneAuthData } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFirebaseError = (error) => {
    let errorMessage = "";
    switch (error) {
      case "auth/internal-error":
        errorMessage = "Something went wrong. Please try again.";
        console.log(error);
        break;
      case "auth/error-code:-39":
        errorMessage =
          "We're experiencing technical difficulties. Please try again later.";
        break;
      case "auth/argument-error":
        errorMessage =
          "We're experiencing technical difficulties. Please try again later.";
        break;
      case "auth/too-many-requests":
        errorMessage = "Too many request attempts. Please try again later.";
        break;
      case "auth/invalid-phone-number":
        errorMessage = "Invalid phone number. Please try again.";
        break;
      case "auth/invalid-verification-code":
        errorMessage = "Invalid verification code. Please try again.";
        break;
      case "auth/code-expired":
        errorMessage =
          "Verification code has expired. Please request a new code.";
        break;
      case "auth/missing-verification-code":
        errorMessage = "Please enter the verification code.";
        break;
      case "reCAPTCHA has already been rendered in this element":
        errorMessage = "Something went wrong. Please try again.";
        break;
      case "auth/network-request-failed":
        errorMessage =
          "Something went wrong. Please reload page and try again.";
        break;
      case "auth/user-not-found":
        errorMessage = "User not found. Please sign up.";
        break;
      case "auth/email-already-in-use":
        errorMessage =
          "Email address already in use. Please try a different email.";
        break;
      default:
        errorMessage = error;
        break;
    }
    setErrorWithTimeout(errorMessage);
  };

  useEffect(() => {
    if (error) {
      handleFirebaseError(error);
    }
  }, [error]);

  useEffect(() => {
    fetchPasswordPolicySetting()
      .then((isStrong) => {
        setIsStrongPasswordPolicy(isStrong);
      })
      .catch((error) => {
        console.error("Error fetching password policy:", error);
      });
  }, []);

  useEffect(() => {
    // Clear the reCAPTCHA widget when unmounts
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        if (error) {
          handleFirebaseError(error);
        }
      }
    };
  }, []);

  const validatePasswords = () => {
    if (formData.password !== formData.confirmPassword) {
      return "Passwords do not match.";
    }

    if (!validatePassword(formData.password, isStrongPasswordPolicy)) {
      return isStrongPasswordPolicy
        ? "Password must be at least 8 characters long, must contain at least one number and a special character."
        : "Password must be at least 6 digits long.";
    }

    return ""; // No validation error
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value, type, checked } = e.target;
      const newValue = type === "checkbox" ? checked : value;
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }
  };

  const setErrorWithTimeout = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError("");
    }, 4000);
  };

  const registerUser = async () => {
    setIsLoading(true);
    try {
      const userRequest = {
        fullName: formData.fullName,
        email: formData.email,
        address: formData.address,
        country: formData.country.label,
        mobilePhone: formData.mobilePhone,
        password: formData.password,
        jointAccount: formData.jointAccount,
        secondaryAccountHolder: formData.secondaryAccountHolder,
        createdAt: getCurrentDate(),
      };
  
      // Add user request to Firestore
      const userRequestId = await registerNewUser(db, auth, userRequest);
  
      if (!userRequestId) {
        throw new Error("Failed to signup.");
      }

      Swal.fire({
        title: "Success",
        text: "Signup successful. Proceed to login.",
        icon: "success",
        showConfirmButton: false,
        timer: 4000,
      });
      setVerificationModal(false);
      resetForm();
      navigate("/");
    } catch (error) {
      console.error("Error registering user:", error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const code = verificationCode.join("");

    try {
     await confirmationResult.confirm(code);
      setIsVerified(true);
      registerUser();
      setVerificationModal(false);
    } catch (error) {
      console.error(error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkForUser = async (email, mobilePhone) => {
    const adminDashRef = collection(db, "admin_users");
    let userExists = false;
    try {
      const querySnapshot = await getDocs(adminDashRef);
      for (const doc of querySnapshot.docs) {
        const userRequestRef = collection(db, "admin_users", doc.id, "userRequests");
        const userRequestsSnapshot = await getDocs(userRequestRef);
        
        // eslint-disable-next-line no-loop-func
        userRequestsSnapshot.forEach((userDoc) => {
          if (userDoc.data().email === email || userDoc.data().mobilePhone === mobilePhone) {
            userExists = true;
          }
        });
  
        if (userExists) {
          setErrorWithTimeout("User already exists with this email or phone number.");
          setIsLoading(false);
          return true;
        }
      }
  
      return false;
    } catch (error) {
      console.error("Error checking for user:", error);
      return false;
    }
  };

  const sendVerificationCode = async () => {
    setIsLoading(true);
    const userExists = await checkForUser(formData.email, formData.mobilePhone);
    if (userExists === false || !userExists) {
    try {
      const confirmation = await signInWithPhoneNumber(
        auth,
        formData.mobilePhone,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmation);
      setPhoneAuthData({
        phoneNumber: formData.mobilePhone,
        confirmationResult: confirmation,
      });
      Swal.fire({
        title: "Verification code sent",
        text: "Please check your phone for the verification code.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      setVerificationModal(true); // Open the verification modal
    } catch (error) {
      handleFirebaseError(error.code);
    } finally {
      setIsLoading(false);
    }}
  };

  const resendVerificationCode = () => {
    if (!canResend) return;
    setCanResend(false);
    setCounter(15);
    sendVerificationCode();
  };

  const resendCode = () => {
    setCounter(15);
    setCanResend(false);
    resendVerificationCode();
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Validation checks
    const passwordValidationResult = validatePasswords();
    if (passwordValidationResult) {
      setErrorWithTimeout(passwordValidationResult);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorWithTimeout("Passwords do not match.");
      return;
    }

    if (!validatePassword(formData.password, isStrongPasswordPolicy)) {
      setErrorWithTimeout("Password must meet the required criteria.");
      return;
    }

    setIsLoading(true);
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "sign-up-button",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": () => {},
        },
        auth
      );

      // Send the verification code
      sendVerificationCode();
    } catch (error) {
      console.error(error);
      handleFirebaseError(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormState);
  }

  return (
    <section className="register_container">
      <div className="register_img">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="register_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Register</h1>
          <p className="subtitle">Become a user by creating an account.</p>
          <span
            className="help_icon"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            ?
            {showTooltip && (
              <div className="tooltip">
                <strong>Password Requirements:</strong>
                <ul>
                  <li>At least 8 characters long</li>
                  <li>One uppercase letter</li>
                  <li>One lowercase letter</li>
                  <li>One number</li>
                  <li>One special character (!@#$%^&*)</li>
                </ul>
              </div>
            )}
          </span>
        </div>
        <form className="form_wrap">
          <div className="joint-account-container">
            <label className="joint-account-label" htmlFor="jointAccount">
              Joint Account
            </label>
            <input
              type="checkbox"
              name="jointAccount"
              checked={formData.jointAccount}
              onChange={handleChange}
              className="joint-account-checkbox"
            />
          </div>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Primary Account Holder Full Name"
            required
            className="input_field"
          />
          {formData.jointAccount && (
            <input
              type="text"
              name="secondaryAccountHolder"
              placeholder="Secondary Account Holder Full Name"
              value={formData.secondaryAccountHolder}
              onChange={handleChange}
              className="input_field"
            />
          )}
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="input_field"
          />
          <PhoneInput
            type="tel"
            name="mobilePhone"
            countrySelectProps={{ unicodeFlags: true }}
            defaultCountry="US"
            placeholder="Phone Number"
            international
            className="input_field"
            value={formData.mobilePhone}
            onChange={(value) =>
              setFormData({ ...formData, mobilePhone: value })
            }
            required
          />
          <div className="address_group">
            <CountrySelect value={formData.country} onChange={handleChange} />
            <input
              type="text"
              name="address"
              placeholder="Address"
              className="input_field"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="password_field">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              className="pass_field"
              value={formData.password}
              onChange={handleChange}
              required
              disabled={isLoading}
            />
            {showPassword ? (
              <IoEye
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <IoEyeOff
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          <div className="password_field">
            <input
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm Password"
              className="pass_field"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              disabled={isLoading}
            />
          </div>
          {error && <p className="error_msg">{error}</p>}
          {successMessage && <p className="success_msg">{successMessage}</p>}
          {isLoading ? (
            <button className="register_btn" type="submit" disabled>
              <div className="spinner"></div>
            </button>
          ) : (
            <button
              className="register_btn"
              id="sign-up-button"
              type="submit"
              onClick={handleSignup}
            >
              Register
            </button>
          )}
          <div className="login_info">
            <p className="text">Already have an account?</p>
            <Link to={"/"} className="login_text">
              Sign In
            </Link>
          </div>
        </form>
      </div>
      {verificationModal && (
        <PhoneVerification
          isOpen={verificationModal}
          onClose={() => setVerificationModal(false)}
          onVerify={handleVerifyCode}
          onResend={resendCode}
          isLoading={isLoading}
          canResend={canResend}
          counter={counter}
          setCounter={setCounter}
          setCanResend={setCanResend}
          error={error}
          successMessage={successMessage}
          phone={formData.mobilePhone}
          setPhoneNumber={(value) => {
            setFormData({
              ...formData,
              mobilePhone: value,
            });
          }}
          code={verificationCode}
          setCode={setVerificationCode}
        />
      )}
    </section>
  );
}
